<template>
  <div style="height: inherit">

    <b-embed
      v-if="urlViewer"
      type="iframe"
      :src="urlViewer"
      allowfullscreen
    />

  </div>
</template>

<script>
import store from '@/store'
import {
  ref, onUnmounted,
} from '@vue/composition-api'
import {
  BEmbed,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import marketModule from '@store-modules/listings/marketStudy/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Dialog } from '@capacitor/dialog'
import useCapacitor from '@core-custom/utils/capacitor'

export default {
  components: {
    BEmbed,
  },
  computed: {
    ...mapGetters('market', ['paramsUrl', 'urlMarket']),
  },
  async created() {
    await this.getLoadPage()
  },
  methods: {
    async getLoadPage() {
      this.$root.$emit('app::loading', true)

      await store.dispatch('market/getMarketStudy', { general: true }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      this.urlViewer = this.urlMarket

      this.$root.$emit('app::loading', false)
    },
    async showMsgErrorRequest(error) {
      if (useCapacitor.isNative() === true) {
        Dialog.alert({
          title: this.$t('Alerta'),
          message: error.message,
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Alerta'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      }
    },
  },
  setup() {
    const MARKET_MODULE_NAME = 'market'

    if (!store.hasModule(MARKET_MODULE_NAME)) {
      store.registerModule(MARKET_MODULE_NAME, marketModule)

      onUnmounted(() => {
        if (store.hasModule(MARKET_MODULE_NAME)) store.unregisterModule(MARKET_MODULE_NAME)
      })
    }

    const urlViewer = ref('')

    return {
      urlViewer,
    }
  },
}
</script>
